@import 'styles/functions.scss';

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

p {
	color: color(text, primary);
	font-size: 18px;
	line-height: 26px;
	font-size: 18px;
}

main,
body,
header,
footer,
div,
ul,
li,
section,
article {
	box-sizing: border-box;
}

ul {
	list-style: none;
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 0;
}

a {
	text-decoration: none;
}

button,
input,
fieldset {
	border: none;
}

button,
input {
	background: none;
}

button {
	cursor: pointer;
}

input {
	box-shadow: none;
}

a,
p,
li,
span,
input,
button,
label {
	font-family: 'Roboto', sans-serif;
}

fieldset {
	padding: 0;
}
